.editWOFormModal {
  background-color: #eee;
  width: 100%;
  h2 {
    margin: 10px 0;
    color: #666666;
  }
  .mainContainer {
    width: 100%;
  }
}
.closeModal {
  font-size: 1.375em;
  line-height: 0.5;
  position: absolute;
  top: 8px;
  right: 11px;
  color: #aaa;
  text-shadow: 0 -1px 1px rbga(0, 0, 0, 0.6);
  font-weight: bold;
  cursor: pointer;
}
.textBlockSize {
  width: 400px;
  height: 300px;
}

.speedTestBlock {
  width: 95%;
}

.editWOForm {
  width: 100%;
  height: 100%;
  empty-cells: show;
  display: flex;
  flex-direction: column;
  max-width: 600px;
  background-color: #d3d3d3;
  margin: 0 auto;
  div,
  span {
    margin: 10px 0;
  }
  th {
    width: 30%;
    text-align: right;
    margin-right: 20px;
    padding-bottom: 10px;
  }

  .checkBoxes {
    display: block;
    margin: auto;
    width: 300px;
  }

  .dataBlock {
    display: block;
    padding-top: 15px;
    margin: auto;
    width: 100%;
  }
  .dataBlock:first-of-type {
    padding-top: 0;
  }
  .centerContent {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    max-width: 400px;
    margin: auto;
  }

  .centerContentCircles {
    display: table-footer-group;
    justify-content: center;
    padding-top: 10px;
    margin: auto;
  }

  button {
    margin: 20px auto;
    font-family: Arial, Verdana, sans-serif;
    background-color: #4f2471;
    color: #ffffff;
    font-size: 10pt;
    line-height: 130%;
    text-decoration: none;
    font-weight: bold;
    height: 25px;
    border: none;
    cursor: pointer;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
  }
  button:disabled {
    background-color: #808080;
  }
  .header {
    width: 100%;
    display: grid;
    font-size: 1em;
    color: grey;
  }

  .title {
    padding: 5px 20px;
    background-color: #4f2471;
    color: #ffffff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 1000000px;
    margin: auto;
    justify-content: center;
  }
  .dateTime {
    text-align: center;
    font-size: 1.25em;
  }
  .inlineBlock {
    width: 100%;
    display: inline-block;
    vertical-align: middle;
  }

  .yesNo {
    justify-content: center;
  }

  .siteSurveyBody {
    margin: auto;
  }
  .lineCheck {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    padding-top: 10px;
  }

  .updateTd {
    text-align: center;
  }
  .item {
    margin: 20px 0;
  }

  .marginCheckBox {
    margin-left: 150px;
  }

  .spaceEvenly {
    display: flex;
    justify-content: space-evenly;
  }
  .spaceBetween {
    display: flex;
    justify-content: space-between;
  }
  .spaceAround {
    display: flex;
    justify-content: space-around;
  }
}

.displayString {
  span {
    margin: 10px 0;
  }
}
.attachmentUpload {
  span {
    margin-right: 20px;
  }
}

.center {
  text-align: center;
}
.marginAuto {
  margin: auto;
}
.inputsDiv{
  padding-left:20px;
  padding-right:20px;
  width:80%;
  text-align:right;
}
.inputsDiv input, select{
  width:60%;
}
@media (max-width: 300px) {
  .speedTestBlock {
    font-size: 0.75em;
  }
  .editWOForm {
    .checkBoxes {
      width: 300px;
    }
    .header {
      font-size: 0.75em;
      display: block;
      align-items: center;
    }
  }
}

@media (max-width: 400px) {
  .textBlockSize {
    width: 70%;
  }
}

@media (min-width: 400px) {
  .speedTestBlock {
    width: 300px;
  }
}

@media (max-width: 280px) {
  .editWOForm {
    .checkBoxes {
      width: 250px;
    }
  }
}
